import { module as Md } from 'modujs';
import { checkForCoverPattern, cl, replaceBodyClassName } from '@/scripts/utils';
import type Core from '@barba/core/dist/core/src/src/core';
import select from 'dom-select';
import { bus } from '@/scripts/bus.ts';

export default class extends Md {
  barba: typeof Core | null = null;
  busy: boolean = false;

  constructor(m: Md) {
    super(m);

    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }

    this.metas = select.all('meta[name="theme-color"]');
  }

  init() {
    if (!cl(document.documentElement).has('homepage')) {
      cl(document.documentElement).remove('to-load');
    }
    cl('.barba-container').remove('to-load');

    // @ts-ignore
    import('@barba/core').then(({ default: barba }) => {
      this.barba = barba;

      this.barba?.init({
        timeout: 10000,
        debug: false,
        requestError: () => {
          return true;
        },
        prevent: ({ href }) => !!href.match(/\.(xls|xlsx|doc|docx|pdf)$/ig)?.length,
        transitions: [
          {
            name: 'default',
            sync: true,
            from: {
              custom: ({ trigger }: { trigger: any }) => {
                return (
                  trigger &&
                  trigger !== 'filter' &&
                  !cl(trigger as HTMLElement).has('no-transition')
                );
              },
            },
            leave: async (data) => {
              cl(document.body).add('in-transition');
              const menuOpen = select('.header.menu-opened');
              const currentPattern = select(
                '.pattern-component.for-transition',
                data.current.container,
              );
              const nextPattern = select('.pattern-component.for-transition', data.next.container);

              if (nextPattern) {
                this.call(
                  'update',
                  { trigger: data.trigger, next: nextPattern.parentNode },
                  'snake',
                );
              }

              if (menuOpen) {
                // Menu is open -> animation logic is in the menu component
                cl(document.body).add('menu-transition');
                this.call('closeMenu', !nextPattern || cl(nextPattern).has('hidden'), 'menu');

                // menu open but next page doesn't have a pattern
                // but not scrolled yet
                if (
                  ((!nextPattern && !cl(currentPattern).has('menu-animating')) ||
                  (nextPattern && cl(nextPattern).has('hidden')))
                  && !checkForCoverPattern(currentPattern)
                ) {
                  cl(document.body).add('transition-off');
                }
              } else {
                // menu is closed do default transition
                if (cl(nextPattern).has('hidden') || !nextPattern) {
                  // menu is closed but next page has hidden pattern
                  cl(document.body).add('transition-fade');
                } else {
                  cl(document.body).add('default-transition');

                  if (
                    currentPattern
                    && !cl(currentPattern).has('for-search')
                    && checkForCoverPattern(currentPattern)
                  ) {
                    // menu is closed but current page has visible top pattern
                    cl(document.body).add('pattern-reset');
                  }
                }

                // slide transition pattern off if next page don't have one
              }

              // cover page pattern if not menu transition
              if (!checkForCoverPattern(currentPattern)) {
                cl(document.body).add('curtain-over');
              }

              return new Promise((resolve) => {
                if (data.next.namespace !== 'people') {
                  cl('.filters').remove('filters-fixed');
                }
                setTimeout(resolve, cl(nextPattern).has('hidden') ? 700 : 1000);
              }).then(() => {
                window.scrollTo(0, 0);
              });
            },
          },
          {
            name: 'filters',
            sync: true,
            leave(data): Promise<any> | void {
              cl(document.body).add('filter-transition');
              const stickyFilter = select('.filters-sticky', data.next.container);
              const stickyFilterEl = select('.filters-sticky-el', data.current.container);
              window.scrollTo(
                0,
                stickyFilter?.getBoundingClientRect().top +
                  window.scrollY -
                  stickyFilterEl?.getBoundingClientRect().height || 0,
              );

              if (!cl('.filters').has('filters-sticky')) {
                cl('.filters').remove('filters-fixed');
              }

              return Promise.resolve();
            },
          },
        ],
      });

      this.barba?.hooks.leave((data) => {
        cl(document.body).add('first');
        this.setBusy(true);
        this.setPageColour(data?.next.container);
        this.call('next', data?.next.container, 'filters');
        this.call('close', null, 'modal');

        if (cl(data?.next.container).has('hidden-footer')) {
          cl('.footer').add('hidden');
        } else {
          cl('.footer').remove('hidden');
        }
      });


      this.barba?.hooks.enter((data) => {
        replaceBodyClassName(data?.next.namespace || 'page');
        // this.call("lockScroll", null, "lenis");
        // release after animation
        // setTimeout(() => {
        //   this.call("unlockScroll", null, "lenis");
        // }, 50)
      });

      this.barba?.hooks.after((data) => {
        // window.scrollTo(0, 0);
        cl(document.body).remove([
          'default-transition',
          'transition-off',
          'in-transition',
          'pattern-reset',
          'menu-transition',
          'transition-fade',
          'curtain-over',
        ]);

        this.setBusy(false);
        if (data) {
          cl(data.next.container).remove('to-load');
          this.call('destroy', data.current.container, 'app');
          this.call('update', data.next.container, 'app');
          try {
            this.call('update', data.next.container, 'global');
          } catch (e) {
            console.log(e);
          }
        }

        setTimeout(() => {
          cl(document.body).remove('filter-transition');
        }, 400);

        requestAnimationFrame(() => {
          if (data?.next.namespace === 'homepage') {
            bus.emit('worm_ready', 'home-cover');
          }
        })
      });
    }).catch();
  }

  go({ url, trigger = 'barba' }: { url: string; trigger: string }) {
    if (this.barba) {
      this.barba.go(url, trigger as any).then();
    }
  }

  setMetas(color: string) {
    this.metas.forEach((meta: HTMLMetaElement) => {
      meta.setAttribute('content', color);
    });
  }

  setPageColour(container = select('.barba-container')) {
    if (container.dataset.barbaColour) {
      document.body.style.setProperty('--bg-color', container.dataset.barbaColour);
      this.setMetas(container.dataset.barbaColour);
      cl(document.body).add('has-color');
    } else {
      document.body.style.removeProperty('--bg-color');
      this.setMetas('#ffffff');
      cl(document.body).remove('has-color');
    }

    if (container.dataset.barbaPColour) {
      document.body.style.setProperty('--pattern-bg', container.dataset.barbaPColour);
    } else {
      document.body.style.removeProperty('--pattern-bg');
    }
  }

  setBusy(busy: boolean) {
    this.busy = busy;
    this.el.dispatchEvent(new CustomEvent('busy', { detail: { busy } }));
  }

  push(url: string) {
    this.barba?.history.add(url, 'barba', 'push');
  }
}
